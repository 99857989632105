import { ApiAction } from '../api-actions';
import { ApiResourceModule } from '../api-modules/api-resource-modules';
import { linkModuleAndAction } from '../link-module-and-action';

export const AdvertismentsPermissions = {
	CREATE_ADVERTISEMENT_PERMISSION: linkModuleAndAction(ApiResourceModule.ADVERTISEMENTS, ApiAction.CREATE),
	DELETE_ADVERTISEMENT_PERMISSION: linkModuleAndAction(ApiResourceModule.ADVERTISEMENTS, ApiAction.DELETE),
	READ_ADVERTISEMENT_PERMISSION: linkModuleAndAction(ApiResourceModule.ADVERTISEMENTS, ApiAction.READ),
	UPDATE_ADVERTISEMENT_PERMISSION: linkModuleAndAction(ApiResourceModule.ADVERTISEMENTS, ApiAction.UPDATE),
};
